<template>
	<div class="monthIncomeSummary">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">记账日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click.stop="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<table>
				<tr>
					<th colspan="2">统计</th>
					<th></th>
				</tr>
				<tr>
					<td colspan="2">销售收入(订单核销/配货对账结清/收款码)</td>
					<td>{{ tableData.xs.xsTotal }} </td>
				</tr>
				<tr>
					<td colspan="2">销售成本(入库入账)</td>
					<td>{{ tableData.xs.xsCost }} </td>
				</tr>
				<tr>
					<td colspan="2">毛利润(销售收入-销售成本)</td>
					<td>{{ tableData.xs.xsProfit }} </td>
				</tr>
				<tr>
					<td colspan="2">毛利率(毛利润/销售收入)</td>
					<td>{{ tableData.xs.xsProfitRate }}</td>
				</tr>
				<tr v-for="(item,index) in tableData.sr.rows">
					<td :rowspan="tableData.sr.rows.length" v-if="index == 0">其他收入</td>
					<td>{{ item.type_text }}</td>
					<td>{{ item.amount }} </td>
				</tr>
				<tr>
					<td colspan="2">其他收入总计</td>
					<td>{{ tableData.sr.total }} </td>
				</tr>
				<tr v-for="(item,index)  in tableData.fy.rows">
					<td :rowspan="tableData.fy.rows.length" v-if="index == 0">运营费用</td>
					<td>{{ item.type_text }}</td>
					<td>{{ item.amount }} </td>
				</tr>
				<tr>
					<td colspan="2">运营费用总计</td>
					<td>{{ tableData.fy.total }} </td>
				</tr>
				<tr class="important">
					<td colspan="2">净利润</td>
					<td>{{ tableData.profit }} </td>
				</tr>
				<tr class="important">
					<td colspan="2">净利润率(净利润/总收入)</td>
					<td>{{ tableData.profitRate }} </td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				loading: true,
				tableData: {}
			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentMonth() + "-01", this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()
		},
		methods: {
			mergeRows({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex <= 2) {
					return {
						rowspan: 3,
						colspan: 1
					};
				}
			},
			getList() {
				this.$http.post(`/erp/v1/financial/report`, {
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 获取当前年月日
			getCurrentMonth() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				return `${year}-${month}`;
			}
		},
	}
</script>

<style lang="scss" scoped>
	table {
		font-size: 26px;
		min-width: 800px;
		line-height: 60px;
		text-align: left;

	}

	th,
	td {
		padding-left: 20px;
		border: 1px solid rgb(228, 230, 232);
		border-collapse: collapse;
	}

	.important {
		font-weight: 600;
	}
</style>